.timer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
  align-items: center;
  justify-content: center;
}
.thisWeekTimer {
  background-color: #ccffcc;
  text-align: center;
}
.thisWeekTimerList {
  background-color: #ccffcc;
}

.timer div b {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--fontFamily);
  color: var(--primColor);
  text-transform: capitalize;
}
.timer div {
  font-size: 22px;
  font-weight: 400;
  font-family: var(--fontFamily);
  color: var(--primColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timeExtendedGrid {
  min-height: 35px;
}
.timer .ext_bidding {
  color: red;
}
.lot_closed {
  font-size: 1.2rem !important;
}
.socket_disconnect {
  font-size: 1.2rem !important;
  color: red;
}
@media screen and (max-width: 600px) {
  .productView .productInfo .productDetails .bidderSection span.span h2 {
    color: #000;
  }
}
