:root {
  --fontFamily: "Source Sans Pro", sans-serif !important;
  --primColor: #000000;
}

body,
.MuiSelect-outlined.MuiSelect-outlined {
  text-transform: uppercase;
}

.App {
  text-align: center;
  /* mix-blend-mode: overlay; */
}

.App.greybg {
  background-color: #f7f7f7;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: var(--primColor) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.actnDscrptn {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.aucDescImg {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin: 10px auto;
}

@media (max-width: 500px) {
  .aucDescImg {
    max-width: 100%;

    max-height: 150px;
  }
}
