body {
  margin: 0;
  font-family:
    var(--fontFamily),
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.customContainer {
  width: 100% !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
  max-width: 1600px;
  margin: 0 auto;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
  border-color: var(--primColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
  color: var(--primColor);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primColor) !important;
  border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin: 0;
}

.customTextArea .textarea .MuiInputBase-root {
  border: 1px solid #0000;
}

.MuiInputBase-root,
.MuiTypography-body1 {
  font-family: var(--fontFamily) !important;
}

.customInput .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root,
.customSelect .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
  border-radius: 0 !important;
  height: 48px;
}

.customInput
  .MuiFormControl-root.MuiTextField-root
  .MuiOutlinedInput-root
  .MuiInputBase-input {
  height: auto;
}

.customTextArea,
.customInput,
.customSelect {
  margin-bottom: 20px;
}
.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 12px !important;
}

input.MuiInputBase-input.MuiOutlinedInput-input::placeholder {
  color: #999999;
  opacity: 1;
}
.menuItem button i svg {
  font-size: 30px;
}
.menuItem button i {
  padding: 0 3px;
}
.customInput label b,
.customSelect label b {
  color: #d21010;
  padding-left: 3px;
}
.borderless input {
  padding: 0;
}
.borderless fieldset {
  border: none;
}

.customInput label,
.customSelect label {
  margin-bottom: 13px;
  text-align: left;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.cursorDecoy {
  cursor: pointer;
}

.wrchkPopup .MuiDialogTitle-root {
  height: 60px;
}

.wrchkPopup .dialogBody .regFrom {
  margin: 0px !important;
}

.wrchkPopup .dialogBody p {
  text-align: center;
}

input:-webkit-autofill {
  /* color: #fff; */
  /* -webkit-text-fill-color: #fff; */
  -webkit-background-clip: text;
  box-shadow: 0 0 0 50px transparent inset;
  /* caret-color: #fff; */
}

.auctionPassword {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.auctionPassword h5 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.auctionPassword h6 {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
}

.auctionPassword .customInput label {
  display: none;
}

.proxy-bid-content p {
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
}
.proxy-bid-content h3 {
  display: block;
  text-transform: uppercase;
  text-align: center;
  color: red;
  margin: 15px 0;
}
.proxy-check {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
  padding: 0 15px;
}
.proxy-check .MuiFormGroup-root .MuiButtonBase-root {
  font-size: 15px;
}

.gridCard .gridCardBody .timeExtended {
  width: auto;
  max-width: 60%;
  max-height: 35px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.productView .pvActionItems .timeExtended {
  width: auto;
  max-width: 100%;
  max-height: 45px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.listvw-table .mydivv .timeExtended,
.listvw-table .mydivv_ext .timeExtended {
  width: auto;
  height: 35px;
  display: block;
  margin: 0;
}

.gridCard .gridCardBody .timer.extendedTimedown > div,
.gridCard .gridCardBody .timer.extendedTimedown b {
  color: #ef3403;
}

.pseudoLabel .customInput {
  margin-top: 12px;
}

.MuiCollapse-root > .MuiCollapse-wrapper .MuiButton-label {
  text-decoration: underline !important;
}

.container .customContainer .landing {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .pseudoLabel .customInput {
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .customContainer {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .customInput
    .MuiFormControl-root.MuiTextField-root
    .MuiOutlinedInput-root
    .MuiButton-root {
    min-width: auto;
  }
  .customInput,
  .customSelect,
  .customTextArea {
    margin-bottom: 5px;
  }

  .regFrom .MuiButton-outlinedPrimary {
    height: 30px;
    width: fit-content;
  }

  .MuiButton-containedPrimary:hover {
    background: var(--primColor) !important;
    color: white !important;
  }
  .proxy-bid-content .proxy-check {
    flex-direction: column;
    padding: 0 15px;
  }
  .proxy-bid-content .proxy-check .MuiFormControlLabel-root {
    margin: 10px 0;
  }
}

@media (max-width: 500px) {
  .hideScroll::-webkit-scrollbar {
    width: 0.1em;
  }

  .hideScroll::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .hideScroll {
    overflow-y: auto;
  }

  .gridCard .gridCardBody .timeExtended {
    width: auto;
    height: 35px;
  }
}

@media (max-width: 445px) {
  .gridCard .gridCardBody .timeExtended {
    height: 28px;
  }
}

@media (max-width: 400px) {
  .auctionPassword {
    max-width: 90%;
  }
}
