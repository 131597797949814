.landing .MuiButtonBase-root.btn {
  width: 100%;
  margin-bottom: 15px;
}

.landing .landingBtns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.landing .landingBtns .primButton {
  min-width: 200px;
}

.landing .landingBtns .primButton button {
  margin-bottom: 0;
}

.landingBanner .lazy-load-image-loaded {
  /* overflow: hidden; */
  margin-bottom: 0;
}

.landingBanner .slick-initialized .slick-slide > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  margin-bottom: 4px;
}

.landingBanner .landingImg {
  width: 100% !important;
  height: 100%;
  aspect-ratio: 4 / 5;
  object-fit: cover;
  margin-bottom: 0;
  transition: 0.5s all ease-out;
}

.landing .p {
  font-size: 15px;
  font-weight: 400;
  font-family: var(--fontFamily);
  text-transform: uppercase;
  color: var(--BCCWBlack);
  line-height: 24px;
  margin-bottom: 25px;
  /* margin-top: 2rem; */
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  max-height: 990px;
  background-size: cover;
}

.landing button {
  margin-bottom: 10px;
}

.landing .landingTopTitle {
  margin-top: 20px;
}

.landing .primButton .MuiButtonBase-root:hover {
  background-color: var(--primColor);
  color: #fff;
}

.landing .landingImg {
  width: 100%;
  height: 100%;
  opacity: 1;
  animation-duration: 1.5s;
}

.landing .landingBanner {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 2fr 2fr;
  gap: 0px;
  /* max-width: 200px; */
  overflow: hidden;
}

.landingBanner .bannerImageContainer {
  position: relative;
  height: 600px;
}
.bannerImageContainer.visible {
  display: block;
}
.bannerImageContainer.hidden {
  display: none;
}
.animate__animated.animate__fadeIn,
.animate__animated.animate__fadeOut {
  --animate-duration: 3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .landing button {
    margin-bottom: 10px;
  }

  .landing .p {
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 992px) {
  .lazy-load-image-background.blur.lazy-load-image-loaded > img {
    /* max-height: 292px; */
    background-size: cover;
  }
}
@media (max-width: 767px) {
  .landingBanner .bannerImageContainer {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .landingBanner .slick-initialized .slick-slide > div {
    gap: 2px;
    margin-bottom: 2px;
  }
  .landingBanner .bannerImageContainer {
    height: 235px;
  }
  .landing .landingBtns .primButton {
    width: 100%;
  }

  .landing .landingBtns .primButton button {
    height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .landingBanner .bannerImageContainer {
    height: 200px;
  }
}
@media (max-width: 340px) {
  .landing .p {
    font-size: 13px;
  }
}
