.header {
  padding: 50px 0;
  padding-bottom: 0;
  position: relative;
}
.header img {
  width: 150px;
}
.proxy-bid-content.proxy-custom {
  padding: 20px;
}
.proxy-bid-content.proxy-custom .MuiFormControlLabel-root {
  align-items: flex-start;
}
.proxy-bid-content.proxy-custom button.MuiButton-containedPrimary {
  width: auto;
  float: left;
}
.navItems a:hover,
.navItems a:focus {
  color: var(--primColor);
}
.navItems a.active {
  color: var(--primColor);
}
.navItems {
  padding: 30px 0;
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
}
.navItems a {
  color: #707070;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 6px 8px;
  text-decoration: none;
  /* max-width: 175px; */
  display: inline-block;
}
.avathar a svg {
  font-size: 25px;
  color: #000;
}
.avathar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 43px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  top: 70px;
  z-index: 10;
}

.avathar .menuItem .MuiBadge-badge {
  background-color: #ff0000;
}

.custom-menu .MuiPaper-root .MuiList-root {
  background-color: #3f3f3f;
}

.custom-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--fontFamily);
}
.custom-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  color: #8d8d8d;
}

.auction-link {
  color: #707070;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 6px 8px;
  text-decoration: none;
  /* max-width: 175px; */
  display: inline-block;
}
.custom-menu .MuiPaper-root {
  border: none !important;
}

.header .site-logo {
  object-fit: contain;
}
.header .site-logo:hover {
  cursor: pointer;
}
.myaccount-btn .MuiButtonBase-root {
  font-size: 13px;
  font-family: var(--fontFamily);
}
.myaccount-btn .MuiButtonBase-root.active {
  color: #000;
  font-weight: 600;
}
.auctionNav {
  margin-right: 0;
}
.mobile-res-header .site-logo-res {
  width: 130px;
  object-fit: contain;
}

.mobile-res-header .MuiListItem-gutters {
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.mobile-res-header .MuiList-root .MuiButtonBase-root {
  border: none;
}

.mobile-res-header .MuiCollapse-wrapperInner {
  border-bottom: 1px solid #e2e2e2;
}

.sidedrawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-mobile-logo .site-logo {
  width: 130px;
  height: 60px;
  object-fit: contain;
}
/* .sidedrawer-menues,
.site-mobile-logo {
  margin: 0 1rem;
} */
.sidedrawer-menues .MuiButton-root {
  min-width: fit-content;
}
.navItems .auctionNav {
  justify-content: center;
}
.mobile-search-view .inputSearch {
  position: unset;
  margin: 0 auto;
}
.tab-search .inputSearch {
  position: unset;
}
.mobile-search-view {
  margin: 1rem 0;
}

.myaccount-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customAccordian.MuiAccordion-root {
  display: inline;
  box-shadow: none;
  border: none;
  margin: 0;
  min-width: initial;
  position: relative;
}

.customAccordian .MuiCollapse-container {
  position: absolute;
  bottom: 0;
}

.customAccordian .MuiAccordionSummary-content {
  margin: 0;
}

.customAccordian.MuiAccordion-root::before {
  display: none;
}

.customAccordian .MuiAccordionSummary-root {
  min-height: initial;
}

.customMenu.MuiButtonBase-root {
  background-color: #333;
  color: #fff;
  text-transform: uppercase;
}

.customMenu.MuiButtonBase-root:hover {
  background-color: #000;
}

@media (max-width: 1200px) {
  .inputSearch input {
    width: 100% !important;
    max-width: 160px !important;
  }
}
@media (max-width: 1024px) {
  .menulistitems .MuiListItem-button {
    font-size: 14px;
  }
  .sidedrawer-header {
    position: relative;
  }
  .sidedrawer-header .sidedrawer-menues {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .sidedrawer-header .site-mobile-logo {
    margin-right: -35px;
  }
  .sidedrawer-header .site-mobile-logo.ml0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sidedrawer-header .site-mobile-logo + .MuiSvgIcon-root,
  .sidedrawer-header .site-mobile-logo {
    margin-left: auto;
  }
  .sidedrawer-header {
    justify-content: center;
  }
  .Medialogomenu {
    border-bottom: 1px solid #ccc;
    padding-bottom: 25px !important;
    margin-bottom: 15px;
  }
  .mobile-res-header .site-logo-res {
    margin: auto;
    justify-content: center;
  }
  .navItems {
    padding-right: 30px;
    padding-left: 30px;
  }
  .sidedrawer-header {
    padding-top: 10px;
    padding-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .sidedrawer-menues .MuiButton-root {
    min-width: fit-content;
  }
  /* .sidedrawer-menues {
    margin: 0 1rem;
  } */
  /* .site-mobile-logo {
    margin: 0 1.5rem;
  } */
}
@media (max-width: 600px) {
}
@media (max-width: 445px) {
  /* .sidedrawer-menues {
    margin: 0 0.5rem;
  } */
  /* .site-mobile-logo {
    margin: 0 1rem;
  } */
}
