/* ImageShuffle.css */
.image-shuffle {
  position: relative;
  width: 500px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  overflow: hidden;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.contain {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
