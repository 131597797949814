.MuiDialogTitle-root h6 {
  text-transform: uppercase;
}
.MuiDialogTitle-root {
  background: #000;
  color: #fff;
}

.modalDialog .MuiPaper-root {
  /* max-width: 800px; */
  width: auto;
  min-width: 700px;
  border-radius: 0;
}
.dialogBody {
  padding: 20px;
}
.modalDialog .MuiPaper-rounded {
  border-radius: 0;
}
.MuiDialogActions-root button {
  height: 45px;
  width: auto;
  min-width: 120px;
  text-transform: uppercase;
  max-width: 150px;
  font-size: 16px;
}
.MuiDialogActions-root.dialogfoot {
  justify-content: center;
  margin-bottom: 25px;
}

.MuiDialog-root .actionWrapper {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.MuiDialog-root .MuiDialogTitle-root {
  padding-bottom: 10px;
}
.MuiDialog-root .MuiDialogContent-root {
  padding-bottom: 20px;
  padding: 8px 24px;
}
.MuiDialog-root .MuiDialogContent-root h5 {
  font-size: 18px;
}

@media (max-width: 600px) {
  .modalDialog .MuiPaper-root {
    max-width: calc(100% - 15%);
    min-width: calc(100% - 15%);
  }
  .MuiDialogTitle-root h6 {
    font-size: 16px;
  }
  .MuiDialogActions-root button {
    height: 35px;
    width: auto;
    min-width: 100px;
    font-size: 14px;
  }
  .dialogBody {
    padding: 10px;
  }
}
