.inputSearch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid var(--primColor);
  width: fit-content;
  max-height: 48px;
  max-width: fit-content;
  border-radius: 4px;
  /* position: absolute; */
  right: auto;
  left: 0px;
  overflow: hidden;
  top: 50px;
}
.inputSearch button svg {
  font-size: 21px;
  fill: #fff;
}
.inputSearch button {
  background: var(--primColor);
  color: #fff;
  margin: 0;
  border-radius: 2px;
  width: 55px;
  padding: 10px 15px;
}

.inputSearch button:hover svg {
  fill: #000;
}

.inputSearch input {
  width: 250px;
  max-width: 250px;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
}
