.loginForm .loginSide h4,
.loginForm .registerSide h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 27px auto;
  text-transform: uppercase;
  color: var(--BCCWBlack);
}

.loginSide .usernameEmail .customInput:nth-child(1) label {
  text-transform: uppercase;
}

.loginSide .customInput label {
  font-weight: 400;
  text-transform: uppercase;
}

.loginForm .loginSide button.userBtn,
.loginForm .registerSide button.userBtn {
  width: 216px;
}
.loginForm .registerSide p {
  font-size: 16px;
  font-weight: 400;
  text-transform: initial;
  text-align: left;
  color: var(--BCCWBlack);
  margin: 0;
  padding-bottom: 27px;
  text-transform: uppercase;
}

.newbddw {
  text-align: left !important;
}

.loginForm .loginSide {
  width: 50%;
  max-width: 100%;
  padding-left: 84px;
}

.loginForm .registerSide {
  width: 50%;
  max-width: 100%;
  padding: 0 140px;
  box-sizing: border-box;
}

.loginSide .primButton,
.registerSide .primButton {
  width: 216px;
  margin: auto;
}

.registerSection .MuiFormHelperText-root {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  margin-top: 10px;
  color: #000;
}

.loginForm {
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
}

.loginForm form .MuiFormLabel-root {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: var(--BCCWBlack);
  margin-bottom: 15px;
}

.loginForm form {
  max-width: 415px;
  margin: auto;
}

.checkboxLine span,
.checkboxLine a {
  color: var(--BCCWBlack);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.bddwInfoCnt a {
  cursor: pointer;
  color: #fd4040;
}

.bddwInfoCnt {
  font-size: 16px;
  font-weight: 500;
  color: #fd4040;
  text-align: justify;
  font-style: italic;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.checkboxLine .MuiListItem-root {
  padding-left: 0;
  text-transform: uppercase;
}

.avathar .menuItem .MuiButton-label {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.helpertext1 {
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  margin: 0;
  color: #000;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 23px;
}

.helpertext2 {
  text-align: left !important;
  margin-top: -18px;
  text-transform: uppercase !important;
}

.checkboxLine {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
}

.loginForm.forgotPassword {
  justify-content: center;
}

.registerSection .stepCard .MuiStepper-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.registerSection .stepCard {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.registerBtnAlign {
  max-width: 705px;
  margin: auto;
  padding-bottom: 25px;
}

.registerBtnAlign button {
  width: 152px;
}

.registerSection .stepCard .MuiStepper-horizontal {
  background-color: #fff;
}

.regFrom,
.stepTwo,
.registerSection .stepCard .MuiStepper-horizontal {
  max-width: 735px !important;
  margin: auto !important;
}

.registerSection .MuiStepper-root {
  padding: 24px 0;
}

.stepTwo p {
  font-weight: 400;
  font-size: 16px;
  color: var(--BCCWBlack);
  text-transform: uppercase;
  font-family: var(--fontFamily);
  margin: 0;
}

.stepTwo h3 {
  text-transform: uppercase;
  font-family: var(--fontFamily);
  font-weight: 600;
  margin-bottom: 13px;
  font-size: 18px;
  color: var(--BCCWBlack);
  margin-top: 40px;
}

.stepTwo .checkboxLine {
  margin-top: 22px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
  color: var(--BCCWBlack);
  text-transform: uppercase;
  font-family: var(--fontFamily);
}

.stepTwo .checkboxLine .MuiFormGroup-row .MuiFormControlLabel-root {
  margin-bottom: 0;
}

.stepTwo {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 40px;
}

.error-feedback {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
  margin: 0.5rem 0;
}

.flexOtpBtns .primButton {
  margin: 0 5px;
}

.flexOtpBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.otpFieldSet input {
  width: 50px !important;
  text-align: center !important;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #cccc;
  margin: 0 5px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.brfresentBtn {
  text-align: center;
  margin-top: 25px;
}

.stepFstcheckBoXline .MuiFormControlLabel-root {
  align-items: flex-start;
}

.CountryInput.react-tel-input .flag-dropdown .country-list li {
  text-align: left;
}

.CountryInput.react-tel-input .flag-dropdown {
  background-color: #dddddd;
}

.CountryInput.react-tel-input .form-control:focus + .flag-dropdown,
.CountryInput.react-tel-input .form-control:focus {
  border: 1px solid #000;
}

.CountryInput.react-tel-input .form-control {
  width: 100%;
  border-radius: 0;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 14px;
  font-weight: 400;
  color: currentColor;
  padding-left: 55px;
  box-shadow: unset;
}

.CountryInput.react-tel-input .selected-flag {
  width: 45px;
  padding: 0 0 0 11px;
}

@media (max-width: 900px) {
  .loginForm .registerSide {
    padding: 0px 60px;
  }

  .loginForm .loginSide {
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .loginForm {
    /* justify-content: space-around; */
  }

  .loginForm .loginSide {
    width: 40%;
  }

  .loginForm .registerSide p {
    text-align: center;
  }

  .loginForm .registerSide {
    width: 40%;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .helpertext2 {
    margin-top: -5px;
  }

  .loginForm {
    flex-direction: column;
    margin: 0 4rem;
  }

  .loginSide .checkboxLine {
    margin-top: 0;
  }

  .loginForm .loginSide,
  .loginForm .registerSide {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .loginForm .registerSide p {
    font-size: 15px;
  }

  .registerBtnAlign {
    text-align: center !important;
  }

  .customInput label,
  .customSelect label {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .flexOtpBtns {
    flex-wrap: wrap;
  }

  .flexOtpBtns .primButton .MuiButton-root {
    font-size: 14px;
  }

  .flexOtpBtns .primButton {
    width: 100%;
    margin-bottom: 3px;
  }

  .loginForm {
    margin: 0px 1rem;
  }

  .loginSide .primButton,
  .registerSide .primButton {
    width: 170px;
  }

  .checkboxLine span,
  .checkboxLine a {
    font-size: 15px;
  }

  .customInput .MuiFormHelperText-root {
    font-size: 12px;
  }
}

@media (max-width: 445px) {
  .loginForm .registerSide p {
    font-size: 15px;
  }

  .loginSide .primButton,
  .registerSide .primButton {
    width: 170px;
  }

  .checkboxLine span,
  .checkboxLine a {
    font-size: 15px;
  }

  .customInput .MuiFormHelperText-root {
    font-size: 12px;
  }
}

@media (max-width: 340px) {
  .checkboxLine {
    flex-direction: column;
  }

  .checkboxLine span,
  .checkboxLine a {
    font-size: 15px;
  }

  .checkboxLine {
    margin-bottom: 10px;
  }

  .loginForm {
    margin: 0 2rem;
  }
}
